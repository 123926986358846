import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsArrowRightCircleFill } from "react-icons/bs";
import CustomCard2 from "../Card/CustomCard2";
import BlogCard from "../Blogs/BlogCard";
import { endPoints, Image_Url } from "../../Contant/Environment";
import { getAllItems } from "../../Utility/Api";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { InfinitySpin } from 'react-loader-spinner'


function About() {
  const [aboutData, setAboutData] = useState([]);
  const [teamtData, setTeamData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAboutPageData = () => {
    let url = `${endPoints.api.GET_ABOUTPAGE}`;

    getAllItems(url)
      .then((response) => {
        const { status, data, message } = response;
        if (message === "success") {
          setAboutData(data.about)
          setTeamData(data.teams)
          setBlogData(data.blogs)
          setIsLoading(false);
        } else {
          // Handle error or show a message
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAboutPageData();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(aboutData)
  return (
    <>
      {isLoading && <div className="page_loader"><InfinitySpin
        visible={true}
        width="200"
        color="#4fa94d"
        ariaLabel="infinity-spin-loading"
      /></div>}
      <section className="careers-div ">
        <Container fluid>
          <Row>
            <Col md={12}>
              <img
                src={Image_Url + aboutData.banner}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className=" about-margin-career1 sec-padd">
        <Container className='about-margin1'>
          <Row className='align-items-center g-4'>
            <Col md={9}>
              <h2 className="c-heading mb-5">{aboutData.title}</h2>
              <p className='about-tech-para'>
                {aboutData && (
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(aboutData.description) }} />
                )}
              </p>

            </Col>
            <Col md={3}>
              <img src={Image_Url + aboutData.rotate_image} alt="click" className='about-center ps-md-0 ps-5' />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="sec-padd bglight bg-radius mission-about">
        <Container fluid>
          <section className="careers-div ">
            <Container fluid>
              <div className=" sec-padd pt-0">
                <Container>
                  <Row className="g-5">
                    <Col md={6}>
                      <p className="blue-f">Our Mission</p>
                      <h2 className="c-heading mb-5">{aboutData.mission_title}</h2>
                      <p>
                        {aboutData && (
                          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(aboutData.mission_descriptions) }} />
                        )}
                      </p>
                      <section className="sec-padd-t">
                        <Container>
                          <Row className='g-4'>
                            {aboutData.mission_list && aboutData.mission_list.map((data, index) => (

                              <Col md={6}>
                                <img className='about-img mb-3'
                                  src={Image_Url + data.images}
                                  alt=""
                                />
                                <p className='about-c-paragraph'>
                                  {data.title}
                                </p>
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </section>
                    </Col>
                    <Col md={6}>
                      <img
                        src={Image_Url + aboutData.mission_image}
                        alt=""
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </section>
          <section className="careers-div">
            <Container fluid>
              <div className=" sec-padd">
                <Container>
                  <Row>
                    <Col md={12}>
                      <img className='w-100'
                        src={Image_Url + aboutData.banner_second}
                        alt=""
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </section>
          <section className=" about-margin-career">
            <Container className='about-margin'>
              <div className=" ">
                <Container>
                  <Row>
                    <Col md={7}>
                      <h2 className="c-heading ">
                        {aboutData.second_title}
                      </h2>
                    </Col>
                    <Col md={5}>
                      <p>
                        {aboutData.second_descriptions}
                      </p>
                    </Col>
                  </Row>
                  <Row className='mt-5 g-4'>
                    {aboutData.second_list && aboutData.second_list.map((data, index) => (

                      <Col md={4} >
                        <img className='about-img mb-3'
                          src={Image_Url + data.images}
                          alt=""
                        />
                        <p className='about-c-paragraph'>
                          {data.title}
                        </p>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </Container>
          </section>
        </Container>
      </div>
      <Container>
        <section className="sec-padd service-div">
          <Row className="align-items-center">
            <Col md={8}>
              <div className="service-content">
                <img src={require("../../Assets/Images/mail.gif")} alt="mail" />
                <div className="service-text">
                  <h2 className="c-heading f-white n-border">
                    Ready to Work With Us ? <br /> Give Us A Call today.
                  </h2>
                  <p className="f-white">
                    Choose Tech4Logic as your technology partner, and experience
                    the difference that people-powered solutions can make.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="service-button">
                <Link className="btn-style2" to={"/"}>
                  <BsArrowRightCircleFill /> Get Service Today
                </Link>
              </div>
            </Col>
          </Row>
        </section>
        <div className="border-img">
          <img src={require("../../Assets/Images/border.png")} alt="logo" />
        </div>
      </Container>
      <div className="sec-padd about-value-sec">
        <Container fluid>
          <section className="careers-div ">
            <Container fluid>
              <div className=" sec-padd">
                <Container>
                  <Row className="g-5">
                    <Col md={6}>
                      <img
                        src={Image_Url + aboutData.values_image}
                        alt=""
                      />
                    </Col>
                    <Col md={6}>
                      <p className="blue-f">Our Values</p>
                      <h2 className="c-heading mb-5">{aboutData.values_title}</h2>
                      <p>
                        {aboutData.values_descriptions}
                      </p>
                      <section className="sec-padd-t icon-about-sec">
                        <Container>
                          <Row className='g-4'>
                            {aboutData.values_list && aboutData.values_list.map((data, index) => (

                              <Col md={6}>
                                <img className='about-img mb-3'
                                  src={Image_Url + data.images}
                                  alt=""
                                />
                                <h2 className="about-c-heading mb-3">{data.title}</h2>
                                <p className='about-c-paragraph'>
                                  {data.description}
                                </p>
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </section>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </section>
        </Container>
      </div>
      <section className="sec-padd company-culture-section pt-0">
        <Container>
          <Row className='g-4'>
            <p className="para-m  blue-f">Team</p>
            <div className="flex-end sec-padd-b">
              <div className="flex-heading">
                <h2 className="c-heading  mb-5">Meet Our Team</h2>
                <p>
                  At Tech4Logic, our leaders at the helm of the organization bring with them a wealth of experience and expertise in the IT industry. With their visionary approach and unwavering dedication, they have been instrumental in shaping the company's growth and success over the years.
                </p>
              </div>
            </div>
            {teamtData && teamtData.map((data, index) => (
              <Col md={3} xs={6}>
                <div>
                  <img
                    src={Image_Url + data.image}
                    alt=""
                  />
                </div>
                <h3 className='about-content mt-3'>
                  {data.name}
                </h3>
                <p className='about-content-p'>{data.designation}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="sec-padd company-culture-section bglight bg-radius">
        <Container>
          <p className="para-m  blue-f">Office</p>
          <div className="flex-end sec-padd-b">
            <div className="flex-heading">
              <h2 className="c-heading  mb-5">Company Culture</h2>
              <p>
                {aboutData.company_culture_descriptions}
              </p>
            </div>
            <div>
              <Link className="btn-style3 gray-hover" to="">
                Show All
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <section className="sec-padd ">
        <Container fluid>
          <div className="bglight bg-radius">
            <Container>
              <div className="careers-gallary">
                <Row className="g-4 margin-15">

                  <Col md={9}>
                    <Row>
                      {aboutData.company_culture_images && aboutData.company_culture_images.slice(0, 4).map((data, index) => (
                        <Col md={6} key={index}>
                          <img
                            src={Image_Url + data.images}
                            alt="logo"
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>

                  {aboutData.company_culture_images && (
                    <Col md={3}>
                      <img className="big-gallary"
                        src={Image_Url + aboutData.company_culture_images[aboutData.company_culture_images.length - 1].images}
                        alt="logo"
                      />
                    </Col>
                  )}

                </Row>
              </div>
              <div className="mt-4 custom-card1">
                <CustomCard2 list={aboutData.company_culture_list} />
              </div>
              <div className="sec-padd-t careers-blog">
                <BlogCard blogs={blogData} />
              </div>
            </Container>
          </div>
        </Container >
      </section >
    </>
  )
}

export default About