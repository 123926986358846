import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import parse from 'html-react-parser';
import { Image_Url } from "../../Contant/Environment";
import { formatDateByMonth } from "../../Utility/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function BlogCard({ blogs }) {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);


  const sendData = (item) => {
    navigate((`/blog/${item.slug}`), { state: { item } });
  };
  

  return (
    <section className="sec-padd client-section">
      <Container>
        <p className="para-m  blue-f  ">Blogs</p>
        <div className="flex-end sec-padd-b">
          <div className="flex-heading">
            <h2 className="c-heading  mb-5">Our Latest Blogs</h2>
            <p>
              Dive into the world of tech wonders with our regularly updated
              blog. Stay ahead with the latest trends, expert insights, and
              in-depth analyses.
            </p>
          </div>
          <div>
            <Link className="btn-style3 gray-hover" to="/blogs">
              Show All
            </Link>
          </div>
        </div>

        <Row className="align-items-center g-5">
          {blogs && blogs.map((data, index) => (

            <Col lg={3} md={4} sm={6}>
              <div className="blog-card">
                <img
                  src={Image_Url + data.image}
                  alt="logo"
                />

                <div className="blog-info">
                  <span>{data.type}</span> <p>{formatDateByMonth(data.created_at)}</p>
                </div>
                <h4>{data.title}</h4>

                <p className='fixdata'>
                  {parse(data.descriptions)}
                </p>
                <div className="blog-button">
                  <Button onClick={() => sendData(data)}>
                    Learn More <BsArrowRight />
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default BlogCard;
