import React from "react";

export default function Comingsoon() {
  return (
    <div className="sec-padd position-relative under-banner">
      <div className="container">
      <img className="rounded-4"
                src={require("../../Assets/Images/home-banner/Under-Construction.webp")}
                alt="logo"
              />
      </div>
    </div>
  );
}
