import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CsrCard from "../Card/CsrCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import SignUpNews from "../Form/SignUpNews";
import BlogCard from "../Blogs/BlogCard";

function Csr() {
    
  return (
    <>
      <section className="page-banner home-slider">
        <Container fluid className="swiper-slide">
          <img
            className="w-100"
            src={require("../../Assets/Images/test2.png")}
            alt="logo"
          />
          <div className="slider-content">
            <p className="title-banner">
              <span>CSR</span>
            </p>
            <h2>Bridging Tech & Humanity for a Better Tomorrow</h2>
            <p>
              Explore Tech4Logic's profound commitment to society through CSR
              initiatives, where cutting-edge technology meets compassionate
              endeavors, empowering communities, fostering sustainability, and
              driving positive change worldwide.
            </p>
          </div>
        </Container>
      </section>

      <section className="sec-padd">
        <Container>
          <Row className="g-5 align-items-center">
            <Col md={7}>
              <div className="shadow-div bottom-left-circle">
                <h2 className="c-heading m-heading">
                  Empowering Tomorrow's Leaders: Tech4Logic's Education-Centric
                  CSR
                </h2>
                <p>
                  Tech4Logic's CSR approach centers on education, acknowledging
                  its pivotal role in shaping a better society. We're committed
                  to amplifying educational access, ensuring that every child
                  has the opportunity to learn and grow. Through
                  technology-driven initiatives and strategic collaborations, we
                  empower young learners, unlocking their potential for a future
                  full of possibilities. 
                </p>
                <p>
                  Additionally, our efforts extend to community upliftment,
                  fostering a holistic environment for growth and progress.
                </p>
              </div>
            </Col>
            <Col md={5}>
              <div className="shadowimg-div right-circle">
                <img
                  className="border-img-csr"
                  src={require("../../Assets/Images/img1-csr.webp")}
                  alt="logo"
                />
                <img
                  className="absolute-img"
                  src={require("../../Assets/Images/img2-csr.webp")}
                  alt="logo"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="priorities-section">
        <Container fluid>
          <div className="bglight bg-radius sec-padd gap-custom">
            <Container>
              <Row>
                <Col md={6}>
                  <h2 className="c-heading  mb-4">
                    Our Sustainability Priorities
                  </h2>
                  <p>
                    Explore our sustainability commitment, prioritizing
                    eco-friendliness, social responsibility, ethical business
                    practices, and community engagement for a better tomorrow
                  </p>
                </Col>
              </Row>
              <div className="sec-padd">
                <CsrCard />
              </div>
              <section className="sec-padd csr-slider">
              <h2 className="c-heading">
              CSR Achievements 
                  </h2>
                <Row className="g-5 pt-5">
                  <Col md="6">
                    <div className="white-border">
                    <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={true}
          modules={[ Navigation, Autoplay]}
          className="mySwiper home-slider circle-slider"
        >
        <SwiperSlide>
            <img
              src={require("../../Assets/Images/slider-1.png")}
              alt="slider3"
            />
             
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require("../../Assets/Images/slider-1.png")}
              alt="slider3"
            />
             
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require("../../Assets/Images/slider-1.png")}
              alt="slider3"
            />
             
          </SwiperSlide>
        </Swiper>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="post-main-div mt-4">
                    <div className="blog-info"><span>SEO</span> <p>05.07.2022</p></div>
                    </div>
                    <h2 className="c-heading m-heading n-border pt-3">Empowering Lives Through Giving: Our Philanthropic Footprint</h2>
                    <p>
                    Delve into our profound philanthropic journey, where every act of giving holds transformative power. Our recent donation of cycles for kids and sports equipment to an orphanage reflects our unwavering commitment. Beyond this, ongoing sponsorships and a spectrum of contributions affirm our dedication to empowering communities and making a lasting impact on lives, embodying the true essence of philanthropy.
                    </p>
                    <div className="mt-5"><Link className="btn-style3 gray-hover" to="/">Show Images</Link></div>
                  </Col>
                </Row>
              </section>
            </Container>
          </div>
        </Container>
      </section>

      <div className="signup-section">
        <Container>
        <SignUpNews/>
         <div className="sec-padd-t">
         <BlogCard/>
         </div>
        </Container>
      </div>
    </>
  );
}

export default Csr;
