import React from 'react' 
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CustomCard1() {
  return (
    <>
         <div className="custom-card1">
                <Row className="g-4">
                  <Col md={3} sm={6}>
                    <div className="w-card">
                      <img
                        src={require("../../Assets/Images/card-icon1.svg").default}
                        alt="logo"
                      />
                    <h4>Cutting-Edge Technology: Pioneering Innovation at Tech4logic</h4>
                    <p>Tech4logic: Dive into innovation, shaping tools, solving complex problems, and driving real-world impact at the forefront through cutting-edge technology.</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/card-icon2.svg").default}
                        alt="logo"
                      />
                    <h4>Collaborative Culture: Harnessing Diversity's Power</h4>
                    <p>Tech4logic fosters a collaborative culture where diverse talents collaborate, fostering creativity and innovation. Your unique perspective matters as we work together to conquer challenges and reach new heights.</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/card-icon3.svg").default}
                        alt="logo"
                      />
                    <h4>Continuous Learning: Empowering Growth</h4>
                    <p>In the ever-evolving tech world, learning never stops. Tech4logic invests in your growth through ongoing training, ensuring you're equipped with the latest skills and knowledge, regardless of your career stage.</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/card-icon4.svg").default}
                        alt="logo"
                      />
                    <h4>Global Opportunities: Unleash Your Potential Worldwide</h4>
                    <p>Tech4logic's worldwide reach offers diverse experiences. Join our network, collaborate globally, and make a lasting international impact, broadening horizons.</p>
                    </div>
                  </Col> 
                </Row>
              </div>
    </>
  )
}

export default CustomCard1