import React, { useRef, useState, useEffect } from "react";
import UnderText from "../UnderText";
// import BgImage from "../../../Assets/Images/service/aws-banner.webp";
import IconCard from "../IconCard";
import LogicCard from "../LogicCard";
import ServiceFooter from "../ServiceFooter";
import { useParams } from 'react-router-dom';
import { endPoints, Image_Url } from "../../../Contant/Environment";
import {  getAllItems } from "../../../Utility/Api";
import { InfinitySpin } from 'react-loader-spinner'
import Comingsoon from "../../CommingSoon";
import { useNavigate } from 'react-router-dom';

function AwsAnalytics() {
    const { subCat, service } = useParams();
    const [services, setServices] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const getServices = () => {
        let url = `${endPoints.api.GET_SERVICES}${service}`;

        getAllItems(url)
            .then((response) => {
                const { status, data, message } = response;
                if (message === "success") {
                    setServices(data)
                    setIsLoading(false);

                } else {
                    // Handle error or show a message
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        getServices();
    }, [subCat, service]);

    if (!services || Object.keys(services).length === 0) {
        return navigate('/');
    }
    const why_choose = services ? services.why_choose : [];
    // console.log(services);
    return (
        

        <>
            {isLoading && <div className="page_loader">
                <InfinitySpin
                    visible={true}
                    width="200"
                    color="#4fa94d"
                    ariaLabel="infinity-spin-loading"
                />
            </div>}


            <section
                className="service-banner sec-padd"
                style={{
                    backgroundImage: `url(${services.banner ? (Image_Url + services.banner) : 'default-image-url'})`,
                }}
            >
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-6">
                            <div className="service-banner">
                                <h2>{services.main_title}</h2>
                                <p>Home : Services : {services.main_title}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img
                                className="rounded-4"
                                src={services && (Image_Url + services.right_image)}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div className="sec-padd lgray">
                <div className="container">
                    <h2 className="c-heading n-border pb-4">{services.title}</h2>
                    <p>
                        {services.descriptions}
                    </p>
                </div>

            </div>
            <IconCard why_choose={why_choose} title={services.main_title} />
            <section className='sec-padd bg-logic'>
                <div className="container">
                    <h2 className="c-heading n-border">
                        {services.second_title}
                    </h2>
                    <p className='pt-4'>{services.second_descriptions}</p>
                    <h3 className='sec-padd'>
                        {services.third_descriptions}
                    </h3>
                    <div className="row g-md-5 g-4 justify-content-center">
                        {services.service_list && services.service_list.map((data, index) => (
                            <div className="col-md-4 col-sm-6">
                                <div className="border-card">
                                    <img src={(Image_Url + data.images)} alt="" />
                                    <h4>{data.title}</h4>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <ServiceFooter />
        </>
    );
}

export default AwsAnalytics;
