import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import {BsArrowRightCircleFill} from "react-icons/bs";

function Service() {
  return (
    <>
         <section className="page-banner home-slider">
        <Container fluid className="swiper-slide">
          <img
            className="w-100"
            src={require("../../Assets/Images/serviceAwsBanner.webp")}
            alt="logo"
          /> 
        </Container>
      </section> 
      <section className='banner-top'>
      <Container>
        <div className='service-box bottom-r-dots circle-bottom-left'>
     <Row className='g-5'>
      <Col md={7}>
         <h2 className='big-heading mb-4'>AWS Cloud Services</h2>
         <p>The most well-known and successful cloud service provider in the world, Amazon Web Services (AWS) offers over 200 fully functional services from data centers all over the world. Millions of clients use AWS to increase agility, cut costs, and spur innovation, including the largest corporations, most successful governmental organizations, and fastest-growing startups.</p>
         <div className='mt-5'>
          <Link className='btn-style8' to={'/'}><BsArrowRightCircleFill /> Get Service Today</Link>
         </div>
      </Col> 
      <Col md={5} className='text-center'>
      <img
                src={require("../../Assets/Images/img-group.png")}
                alt="slider"
              />
      </Col> 
     </Row>
     </div>
        </Container>
      </section>

      <section className='sec-padd service-contetn'>
            <Container>
              <Row>
                <Col md={7}>
                <p className="blue-f sec-padd-t">Key Features</p>
                <h2 className='c-heading'>Why Choose AWS?</h2> 
                <p>AWS provides a comprehensive platform to help businesses grow and succeed in the digital age.</p>
                </Col>
              </Row>
            </Container>
      </section>
    </>
  )
}

export default Service