import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
import BlogCard from "../Blogs/BlogCard";
import { Link } from "react-router-dom";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";

function Faqs() {
  return (
    <>
      <section className="sec-padd mb-0 pb-0 faqs">
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={4}>
              <h2 className="f-heading">Frequently Asked Questions </h2>
            </Col>
            <Col md={4}>
              <img
                src={require("../../Assets/Images/Question.png")}
                alt="logo"
              />
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row className="faqs-row">
            <Col md={2}></Col>
            <Col md={4}>
              <div className="search">
                <span className="search-icon">
                  <AiOutlineSearch />
                </span>
                <input placeholder="Search FAQ" />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </section>
      <section className="offer-section">
        <Container>
          <section className="sec-padd home-story-section1 faq">
            <div className="w-card-section ">
              <Row className="align-items-center service-bottom">
                <div className="d-flex align-items-center">
                  <img src={require("../../Assets/Images/facilities.png")} alt="bill" width={'34'} />
                  <div className="content">
                    <h2 className="c-headings">Service and facilities</h2>
                    <p className='c-paragraph'>
                      Know in detail about the service we provide.
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="align-items-center pt-5">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When does the AWS Free Tier expire?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>When does the AWS Free Tier expire?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Why can’t I pay with my PayPal?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can I cancel my service?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
              <Row className="align-items-center pt-5 service-bottom">
                <div className="d-flex align-items-center">
                  <img src={require("../../Assets/Images/payment.png")} alt="bill" width={'34'} />
                  <div className="content">
                    <h2 className="c-headings">Billing and payment methods</h2>
                    <p className='c-paragraph'>
                      Learn more about payment and more
                    </p>
                  </div>
                </div>
              </Row>
              <Row className="align-items-center pt-5">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>When does the AWS Free Tier expire?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>When does the AWS Free Tier expire?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Why can’t I pay with my PayPal?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can I cancel my service?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do?</Accordion.Header>
                    <Accordion.Body>
                      Services with a 12-month Free Tier allow customers to use the product for free up to specified limits for one year from the date the account was activated. Services with an Always Free offer allow you to use the product for free up to specified limits as long as you have a valid AWS account. Services with a short term trial are free to use for a specified period of time or up to a one-time limit depending on the service selected. Additionally, short term trial period may start when you first use the service, not when you create your account. When your Free Tier expires or if your application use exceeds the Free Tier limits, you simply pay standard, pay-as-you-go service rates (see each service page for full pricing details). Restrictions apply; see offer terms for more details.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
            </div>
          </section>
        </Container>
      </section>
      <Container className="faq-calldiv ">
        <section className="sec-padd service-div">
          <Row className="align-items-center">
            <Col md={8}>
              <div className="service-content">
                <img src={require("../../Assets/Images/mail.gif")} alt="mail" />
                <div className="service-text">
                  <h2 className="c-heading f-white n-border">
                    Ready to Work With Us ? <br /> Give Us A Call today.
                  </h2>
                  <p className="f-white">
                    Choose Tech4Logic as your technology partner, and experience
                    the difference that people-powered solutions can make.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="service-button">
                <Link className="btn-style2" to={"/"}>
                  <BsArrowRightCircleFill /> Get Service Today
                </Link>
              </div>
            </Col>
          </Row>
        </section>
        <div className="border-img">
          <img src={require("../../Assets/Images/border.png")} alt="logo" />
        </div>
      </Container>
      <section className="home-blog">
        <BlogCard />
      </section>
    </>
  );

}

export default Faqs