import { React, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsArrowRightCircleFill, BsArrowRight } from "react-icons/bs";
import CustomCard1 from "../Card/CustomCard1";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import ApplyForm from "../Form/ApplyForm";
import CustomCard2 from "../Card/CustomCard2";
import BlogCard from "../Blogs/BlogCard";
import CareerModal from "../Card/CareerModal";

function Careers() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <>
      <section className="sec-padd inner-page-header">
        <Container>
          <Row className="align-items-center g-5">
            <Col md={8}>
              <h1 className="b-heading mb-4">
                Beyond Employment: A Journey of Fulfillment and Purpose
              </h1>
              <p className="text-gray">
                Whether you're a seasoned pro or just starting your tech career,
                Tech4logic welcomes individuals who are eager to learn, grow,
                and make an impact. Join us in shaping the future of
                technology. Explore our current job openings and embark on a
                journey of endless possibilities at Tech4logic.
              </p>
            </Col>
            <Col md={3} className="text-end">
              <Link className="btn-style4 btn-c" to={"/"}>
                <BsArrowRightCircleFill /> Apply Now
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="sec-padd ">
        <Container fluid>
          <section className="careers-gradient sec-padd">
            <Container>
              <section className="careers-top">
                <Row className="justify-content-between g-5">
                  <Col md="7">
                    <img
                      className="careers-img1"
                      src={require("../../Assets/Images/career1.webp")}
                      alt="mail"
                    />
                  </Col>
                  <Col md="5">
                    <img
                      className="careers-img2"
                      src={require("../../Assets/Images/career2.webp")}
                      alt="mail"
                    />
                  </Col>
                </Row>
              </section>

              <section className="sec-padd">
                <Col md={6}>
                  <h2 className="c-heading border2 f-white">
                    What Sets Us Apart?
                  </h2>
                  <p className="f-white">
                    Tech4logic: Where innovation thrives, limitless growth,
                    impactful work, and inclusivity flourish. Whether starting
                    your tech career or seeking top talent, Tech4logic is the
                    place to be.
                  </p>
                </Col>
              </section>
            </Container>
          </section>
        </Container>
      </div>

      <section className="margin-career">
        <Container fluid>
          <div className="bglight">
            <Container>
              <div className="img-collpase">
                <CustomCard1 />
              </div>
            </Container>
          </div>
        </Container>
      </section>

      <section className="careers-div ">
        <Container fluid>
          <div className="bglight gap-custom">
            <Container>
              <Row className="g-5 align-items-center">
                <Col md={6}>
                  <p className="blue-f">Apply Today</p>
                  <h2 className="c-heading mb-5">Career Opportunities</h2>
                  <p>
                    We're on the hunt for tech enthusiasts like you. If you're
                    eager to make your mark in the tech industry, this is the
                    place to be. Apply now to join our team and work on exciting
                    projects that will challenge your skills and fuel your
                    passion for technology.
                  </p>
                  <section className="sec-padd-t">
                    <div className="list-items">
                      <div className="flex-center-h">
                        <h4>Front-End Developer</h4>
                        <p>
                          <span>On-Site</span> Experience : 3+ Years
                        </p>
                      </div>
                      <div className="flex-end-center">
                        <CareerModal />
                        <div className="apply-div">
                          <Link to={"/"} className="btn-style5">
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="list-items">
                      <div className="flex-center-h">
                        <h4>Front-End Developer</h4>
                        <p>
                          <span>On-Site</span> Experience : 3+ Years
                        </p>
                      </div>
                      <div className="flex-end-center">
                      <CareerModal />
                        <div className="apply-div">
                          <Link to={"/"} className="btn-style5">
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="list-items">
                      <div className="flex-center-h">
                        <h4>Front-End Developer</h4>
                        <p>
                          <span>On-Site</span> Experience : 3+ Years
                        </p>
                      </div>
                      <div className="flex-end-center">
                      <CareerModal />
                        <div className="apply-div">
                          <Link to={"/"} className="btn-style5">
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="list-items">
                      <div className="flex-center-h">
                        <h4>Front-End Developer</h4>
                        <p>
                          <span>On-Site</span> Experience : 3+ Years
                        </p>
                      </div>
                      <div className="flex-end-center">
                      <CareerModal />
                        <div className="apply-div">
                          <Link to={"/"} className="btn-style5">
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </section>
                </Col>
                <Col md={6}>
                  <div className="careers-img">
                    <img
                      src={require("../../Assets/Images/career4.webp")}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>

      <section className="sec-padd-b">
        <Container>
          <div className="service-div  bg-gradient2 left-circle">
            <div className="sec-padd">
              <Row className="align-items-center">
                <Col md={6}>
                  <h2 className="c-heading n-border f-white">
                    Signup to our newsletter
                  </h2>
                  <p className="p-small f-white">
                    Get the latest LinkMyDeals news straight to your inbox
                  </p>
                </Col>
                <Col md={6}>
                  <Form
                    className="footer-form career-form"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          placeholder="Enter Here"
                          aria-describedby="inputGroupPrepend"
                          required
                        />

                        <Button type="submit">
                          Subscribe <BsArrowRight />
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
          <div className="border-img sec-padd-b">
            <img src={require("../../Assets/Images/border.png")} alt="logo" />
          </div>
        </Container>
      </section>

      <section className="sec-padd-b apply-career-sec">
        <Container>
          <Row className="g-5 align-items-center">
            <Col md={6}>
              <p className=" blue-f">Apply Here</p>
              <h2 className="c-heading mb-5">Apply for Position</h2>
              <p>
                We're the gateway to a rewarding career in the tech world. If
                you're passionate about technology and want to be part of a
                dynamic, forward-thinking team, you're in the right place. Share
                your details below, and let's start your journey.
              </p>
              <p>
                Together, we'll build the future of tech. Your career begins
                here.
              </p>
            </Col>

            <Col md={6}>
              <ApplyForm />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd company-culture-section">
        <Container>
          <p className="para-m  blue-f">Office</p>
          <div className="flex-end sec-padd-b">
            <div className="flex-heading">
              <h2 className="c-heading  mb-5">Company Culture</h2>
              <p>
                Tech4logic strives to create an environment where employees feel
                valued, supported, and motivated to do their best work. The
                company recognizes that a positive company culture leads to
                better performance, higher employee retention, and a more
                engaged workforce.
              </p>
            </div>
            <div>
              <Link className="btn-style3 gray-hover" to="">
                Show All
              </Link>
            </div>
          </div>
        </Container>
      </section>

      <section className="sec-padd ">
        <Container fluid>
          <div className="bglight bg-radius sec-padd">
            <Container>
              <div className="careers-gallary">
                <Row className="g-4 margin-15">
                  <Col md={5}>
                    <img
                      src={require("../../Assets/Images/g1.webp")}
                      alt="logo"
                    />
                    <img
                      src={require("../../Assets/Images/g4.webp")}
                      alt="logo"
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={require("../../Assets/Images/g2.webp")}
                      alt="logo"
                    />
                    <img
                      src={require("../../Assets/Images/g5.webp")}
                      alt="logo"
                    />
                  </Col>
                  <Col md={3}>
                    <img
                      className="big-gallary"
                      src={require("../../Assets/Images/g3.webp")}
                      alt="logo"
                    />
                  </Col>
                </Row>
              </div>
              <div className="mt-4 custom-card1">
                <CustomCard2 />
              </div>

              <div className="sec-padd-t careers-blog">
                <BlogCard />
              </div>
            </Container>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Careers;
