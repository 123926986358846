import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import BlogItem from "./BlogItem";
import { endPoints, Image_Url } from "../../Contant/Environment";
import { getAllItemsData, getAllItems } from "../../Utility/Api";
import { InfinitySpin } from 'react-loader-spinner'

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const getBlogsData = () => {
    let url = `${endPoints.api.GET_HOMEPAGE}`;

    getAllItems(url)
      .then((response) => {
        const { status, data, message } = response;
        if (message === "success") {
          setBlogs(data.blogs)
          setIsLoading(false);

        } else {
          // Handle error or show a message
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBlogsData();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const filterBlogs = (value) => {
    if (value === '1') {
      const sortedBlogs = [...blogs].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setFilteredBlogs(sortedBlogs);
    } else if (value === '2') {
      const sortedBlogs = [...blogs].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setFilteredBlogs(sortedBlogs);
    } else {
      setFilteredBlogs(blogs);
    }
  };
  
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    filterBlogs(e.target.value);
  };
  const final_blogs = filteredBlogs.length > 0 ? filteredBlogs : blogs;
  
  return (
    <>
      {isLoading && <div className="page_loader"><InfinitySpin
        visible={true}
        width="200"
        color="#4fa94d"
        ariaLabel="infinity-spin-loading"
      /></div>}
      <section className="sec-padd blog-page">
        <Container>
          <Row className="align-items-center g-5">
            <Col md={8}>
              <h1 className="c-heading mb-4">
                Blog: Your Gateway to Cutting-Edge Tech Insights
              </h1>
              <p className="text-gray">
                Dive into the world of tech wonders with our regularly updated
                blog. Stay ahead with the latest trends, expert insights, and
                in-depth analyses. From AI breakthroughs to cybersecurity tips,
                our blogs offer it all.
              </p>
            </Col>
            <Col md={4}>
              <div className="filter-div">
                <p>Filter By</p>
                <Form.Select aria-label="Default select example" value={selectedOption}
                  onChange={handleSelectChange}>
                  <option>Select</option>
                  <option value="All">All</option>
                  <option value="1">Order BY ASC</option>
                  <option value="2">Order BY DESC</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec-padd-b">
        <Container>
          <BlogItem blogs={final_blogs} />
        </Container>
      </section>
    </>
  );
}

export default Blogs;
