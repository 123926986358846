// import './App.css';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer"
import RouteList from "./RouteList";
import { BrowserRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import ScrollToTop from "./Components/ScrollTop";
import Row from "react-bootstrap/Row";
import { createBrowserHistory } from "history";

function App() {
  const history = createBrowserHistory();

  return (
    <>
      <BrowserRouter history={history}>
        <ScrollToTop />
        <Header />
        <Container fluid>
          <Row>
            <RouteList />
          </Row>
        </Container>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
