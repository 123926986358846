import React from "react";
import { FaArrowCircleRight } from "react-icons/fa";

function ServiceFooter() {
  return (
    <>
      <section className="service-form sec-padd">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <h2 className="c-heading n-border f-white">
                You can trust us for exceptional service quality.
              </h2>
              <p className="f-white pt-4">
                Provide your contact information, and we'll reach out within 24
                hours
              </p>
            </div>
            <div className="col-md-6">
              <form>
                <div className="row g-md-5 g-4">
                  <div className="col-md-6">
                    <input type="text" placeholder="Full Name*" />
                  </div>
                  <div className="col-md-6">
                    <input type="text" placeholder=" Company Name" />
                  </div>
                  <div className="col-md-6">
                    <input type="tel" placeholder="Contact Number" />
                  </div>
                  <div className="col-md-6">
                    <input type="mail" placeholder="Email ID" />
                  </div>
                  <div className="col-md-6">
                    <button className="service-btn">
                      <span>
                        <FaArrowCircleRight />
                      </span>{" "}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd service-get">
        <div className="container">
          <div className="row align-items-center g-md-5 g-4">
            <div className="col-md-6">
              <h2 className="c-heading f-white n-border">
                Expand your organization with us
              </h2>
            </div>
            <div className="col-md-6">
              <div className="text-end">
                <a href="#" className="ms-auto service-btn btn-light">
                Get A Free Quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceFooter;
