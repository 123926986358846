import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row"; 
 

function ApplyForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <div className="apply-form">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="g-4">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Full name</Form.Label>
            <Form.Control required type="text" placeholder="Enter here" />
            <Form.Control.Feedback type="invalid">
              Please enter your name
            </Form.Control.Feedback>
          </Form.Group> 

          <Form.Group as={Col} md="6" controlId="validationCustomUsername">
            <Form.Label>Contact Number</Form.Label>
            <InputGroup hasValidation>
                
              <InputGroup.Text id="inputGroupPrepend"> +91 </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Enter number"
                aria-describedby="inputGroupPrepend"
                required
              />
               
             
            </InputGroup>
          
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="validationCustom02">
            <Form.Label>Email Address</Form.Label>
            <Form.Control required type="text" placeholder="Email here" />
            <Form.Control.Feedback type="invalid">
            Please enter your Email
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12">
          <Form.Label>Message</Form.Label>
          
        <Form.Control
          as="textarea"
          placeholder="Enter here"
          style={{ height: '100px' }}
          required
        />
    
      </Form.Group>
           
           <p className="p-small pt-5">Attach Resume File ( PDF, .word, .JPG )</p>

      
         <Form.Group className="flex-form mb-3">
            <Form.Label>Attach Your Resume</Form.Label>
            <Form.Control
              type="file"
              required
              name="file" 
 
            />
            
            </Form.Group>
        


         
        </Row>
       <div className="text-center pt-5">
        <Button className="dark-btn" type="submit">Submit form</Button>
        </div>
      </Form>
    </div>
  );
}

export default ApplyForm;
