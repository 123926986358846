import React from "react";
import {  Image_Url } from "../../Contant/Environment";

function IconCard({ why_choose,title }) {
  // console.log(why_choose);
  return (
    <>
      <section className="card-gradient sec-padd">
        <div className="container">
          <h2 className="c-heading f-white n-border">
            Why Choose {title}?
          </h2>
          <div className="row g-md-5 g-4 pt-5">
            {why_choose && why_choose.map((data, index) => (
              <div className="custom-col">
                <div className="icon-box">
                  <img
                    src={Image_Url + data.images}
                    alt="logo"
                  />
                  <h3>{data.title}</h3>
                  <p>
                    {data.descriptions}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default IconCard;
