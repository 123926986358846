import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import sanitizeHtml from 'sanitize-html';
import { Image_Url } from "../../Contant/Environment";
import moment from 'moment';

function BlogDetail() {
  const location = useLocation();
  const blog_details = location.state.item;
 

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="sec-padd blog-details">
        <Container>
          <div className="bglight bg-radius sec-padd">
            <div className="blog-details-img margin-21 p-5">
              <img
                className="w-100"
                src={Image_Url + blog_details.image}
                alt="logo"
              />
              <div className="blog-info mt-5">
                <span className="bg-white">{blog_details && blog_details.type}</span> <p>{blog_details && moment(blog_details.created_at).format('DD.MM.YYYY')}</p>
              </div>

              <Row className="g-5 justify-content-between">
                <Col md={6}>
                  <h2 className="c-heading n-border">
                    {blog_details && blog_details.title}
                  </h2>
                </Col>

                <Col md={3}>
                  <div className="post-profile ">
                    <img
                      src={require("../../Assets/Images/blog1.png")}
                      alt="logo"
                    />
                    <div className="post-profile-name">
                      <h5>Jessica William</h5>
                      <p>Software Dev.</p>
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mt-4 blog-text">
                    <p>
                      {blog_details && (
                        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(blog_details.descriptions) }} />
                      )}

                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BlogDetail;
