import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IoLocationSharp } from "react-icons/io5";
import { IoMdCopy } from "react-icons/io";
import { FaPhone, FaEnvelope } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { endPoints } from "../../Contant/Environment";
import swal from 'sweetalert';
import Loader from "../Loader/index";
import { addItemToken } from "../../Utility/Api";
import { contactSchema } from "../Schemas";
import { useFormik } from "formik";

const initialValues = {
  name: '',
  email: '',
  mobile: '',
  comment: '',
};


function Contact() {

  const { contact } = useSelector((state) => state);
  const contact_data = contact.contact_data;

  const [isLoading, setIsLoadinig] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit,setSubmitting,resetForm  } =
    useFormik({
      initialValues,
      validationSchema: contactSchema,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          const response = await submitApi(values);
          if (response && !response.error) {
            setSubmitting(false);
            resetForm();  
            swal('Success!', response.message, 'success');
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      },
    });

    async function submitApi(formData) {
      try {
        const url = `${endPoints.api.QUERYSUBMIT}`;
        setIsLoadinig(true);
        const response = await addItemToken(url, formData);
        setIsLoadinig(false);
        return response;
      } catch (error) {
        setIsLoadinig(false);
        throw error;
      }
    }

  // console.log(contact_data)
  return (
    <>
      <Container className="sec-padd-t">

        <div className="contact-row ">
          <div className="contact-margin ">
            <Row>
              <Col lg={5} md={6}>
                <div className="contact-img">
                  <div className="mb-4">
                    <h2 className="c-heading text-white">
                      Let’s Work Together
                    </h2>
                  </div>
                  <p className="contact-ph">
                    Contact us today to learn more about how we can help your
                    business thrive in today's fast-paced digital landscape
                  </p>
                  <div className="contact-padd">
                    <div className="contact-number">
                      <FaPhone />
                      <p className="contact-p">Contact</p>
                      <p className="contact-p1">+{contact_data && contact_data.first_mobile_no}</p>
                      <IoMdCopy />
                    </div>
                    <div className="contact-number">
                      <FaPhone />
                      <p className="contact-p">Contact</p>
                      <p className="contact-p1">+{contact_data && contact_data.second_mobile_no}</p>
                      <IoMdCopy />
                    </div>
                    <div className="contact-number">
                      <FaEnvelope />
                      <p className="contact-p">Email ID</p>
                      <p className="contact-p1">{contact_data && contact_data.email}</p>
                      <IoMdCopy />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={7} md={6}>
                <div className="contact-form ">
                  <form onSubmit={handleSubmit}>
                    {isLoading && <Loader spinner={true} visible={isLoading} className="LoaderForm" />}
                    <Row>
                      <Col md={6}>
                        <div className="">
                          <p>
                            <label className="contact-name">Full Name</label>
                            <input
                              size="40"
                              className=" form-control"
                              placeholder="Enter here"
                              type="text"
                              name="name" value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name ? (
                              <small className="text-danger">{errors.name}</small>
                            ) : null}
                          </p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="">
                          <p>
                            <label className="contact-name">
                              Contact Number
                            </label>
                            <input
                              size="40"
                              className=" form-control"
                              placeholder="+91"
                              type="number"
                              name="mobile"
                              value={values.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.mobile && touched.mobile ? (
                              <small className="text-danger">{errors.mobile}</small>
                            ) : null}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="">
                          <p>
                            <label className="contact-name ">
                              Email Address
                            </label>
                            <input
                              className=" form-control"
                              placeholder="Enter here"
                              type="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <small className="text-danger">{errors.email}</small>
                            ) : null}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="">
                          <p>
                            <label className="contact-name  w-100">
                              Comment
                            </label>
                            <textarea
                              cols="10"
                              rows="5"
                              className="w-100 form-control"
                              placeholder="Enter here"
                              name="comment"
                              value={values.comment}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            {errors.comment && touched.comment ? (
                              <small className="text-danger">{errors.comment}</small>
                            ) : null}
                          </p>
                        </div>
                      </Col>
                      <div className="contat-page-button">
                        <button type="submit" className="submit-contact">Submit</button>
                      </div>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Container fluid>
        <section className="card-overlay card-row sec-padd">


          <Container className="content text-center">
            <div className="conact-box-h">
              <h2 className=" tech-office c-heading d-inline">
                Tech4Logic Office
              </h2>
            </div>
            <Row className="g-md-5 g-4">
              {contact_data.office_address && contact_data.office_address.map((data, index) => (
                <Col lg={3} md={6} className="d-flex card-handle">
                  <div className="sbox wadev">
                    <div className="sIcon rounded-circle">
                      <span>
                        <IoLocationSharp />
                      </span>
                    </div>
                    <h5 className="card-h">{data.state}</h5>
                    <p className="card-p">
                      {data.address}
                    </p>
                  </div>
                </Col>
              ))}

            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
}

export default Contact;
