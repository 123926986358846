import { Actions } from "../Actions/Actions";

const initialState = {
  contact_data: {},
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_CONATACT_DATA:
      return {
        ...state,
        contact_data: action.data.contact_data,
      };
     
    default:
      return {
        ...state,
      };
  }
}
