import React, { useRef, useState, useEffect } from "react";
import BgImage from "../../../Assets/Images/service/aws/banner.webp";
import { FaArrowCircleRight } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import { endPoints, Image_Url } from "../../../Contant/Environment";
import { getAllItemsData, getAllItems } from "../../../Utility/Api";
import { InfinitySpin } from 'react-loader-spinner'
import Comingsoon from "../../CommingSoon";
import { useNavigate } from 'react-router-dom';

export default function AWSServiceHome() {
  const { subCat } = useParams();
  const [subCategory, setSubCategory] = useState({});
  const [servicesList, setServicesList] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let slug = subCat.replace('-services', '');
  const getSubCategory = () => {
    let url = `${endPoints.api.GET_SUBCATEGORY}${slug}`;

    getAllItems(url)
      .then((response) => {
        const { status, data, message } = response;
        if (message === "success") {
          setSubCategory(data.sub_category)
          setServicesList(data.services)
          setIsLoading(false);

        } else {
          // Handle error or show a message
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    setIsLoading(true);
    getSubCategory();
  }, [subCat]);
  // if (!subCategory || Object.keys(subCategory).length === 0) {
  //   return navigate('/');
  // }
  // console.log(subCat)
  // if(subCategory.banner != '') return null;
  return (
    <>
      {isLoading && <div className="page_loader">
        <InfinitySpin
          visible={true}
          width="200"
          color="#4fa94d"
          ariaLabel="infinity-spin-loading"
        />
      </div>}
      {subCategory.image && subCategory.banner && subCategory.right_image ?
        <>
          <Container fluid>
            <section
              className="service-banner sec-padd service-home-main"
              style={{
                backgroundImage: `url(${subCategory && (Image_Url + subCategory.banner)})`,
              }}
            >
              <div className="container">
                <div className="row g-5 align-items-center">
                  <div className="col-md-6">
                    <div className="service-banner">
                      <img
                        className="banner-logo"
                        src={subCategory && (Image_Url + subCategory.image)}
                        alt="logo"
                      />
                      <h2>{subCategory && subCategory.title} Cloud Services</h2>
                      <p>
                        {subCategory && subCategory.descriptions}
                      </p>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <img
                      className="service-right-img"
                      src={subCategory && (Image_Url + subCategory.right_image)}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </section>
          </Container>

          <section className="sec-padd service_card_sec">
            <Container>
              <h2 className="c-heading n-border pb-4">Why Choose {subCategory && subCategory.title}?</h2>
              <p>
                AWS provides a comprehensive platform to help businesses grow and
                succeed in the digital age.
              </p>

              <Row className="g-md-5 g-4 mt-5">
                {subCategory.why_choose && subCategory.why_choose.map((data, index) => (
                  <Col lg={3} md={4} sm={6}>
                    <div className="card_equal">
                      <img
                        src={Image_Url + data.images}
                        alt="logo"
                      />
                      <h3>{data.title}</h3>
                      <p>
                        {data.descriptions}
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>

          <section className="service_home_talk sec-padd-t">
            <Container>
              <section className="trust-section sec-padd">
                <Row className="align-items-center g-md-5 g-4">
                  <Col md="8">
                    <h2 className="c-heading f-white">
                      Want to stay ahead of the curve and take advantage of the
                      latest AWS services and technologies?
                    </h2>
                    <p>
                      Tech4Logic team of certified AWS professionals can help you
                      stay up-to-date with the latest AWS innovations and
                      incorporate them into your business operations.
                    </p>
                  </Col>
                  <Col md="4">
                    <button className="service-btn">
                      <span>
                        <FaArrowCircleRight />
                      </span>{" "}
                      Let’s Talk
                    </button>
                  </Col>
                </Row>
              </section>
              <div className="border-img sec-padd-b">
                <img
                  src={require("../../../Assets/Images/border.png")}
                  alt="logo"
                />
              </div>
            </Container>
          </section>

          <section className="sec-padd lgray2">
            <Container>
              <h2 className="c-heading n-border">
                {subCategory && subCategory.second_title}
              </h2>
              <p>
                {subCategory && subCategory.second_descriptions}
              </p>
              <Row className="g-md-5 g-4 sec-padd-t">

                {servicesList && servicesList.map((data, index) => (
                  <div className="c_col-6">
                    <Link to={`/service/${data.slug}`}>
                      {" "}
                      <div className="shadow_card">
                        <img
                          src={require("../../../Assets/Images/service/aws/service-icon2.webp")}
                          alt="logo"
                        />
                        <h3>{data.main_title}</h3>
                      </div>
                    </Link>
                  </div>
                ))}
              </Row>
            </Container>
          </section>
          <section className="sec-padd bg-logic">
            <div className="container">
              <h2 className="c-heading n-border pb-5">
                {subCategory && subCategory.heading}
              </h2>

              <div className="row g-md-5 g-4 justify-content-center">
                <div className="col-md-4 col-sm-6">
                  <div className="border-card border_blue_font">
                    <h4>{subCategory && subCategory.first_box}</h4>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="border-card border_blue_font">
                    <h4>
                      {subCategory && subCategory.second_box}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="border-card border_blue_font">
                    <h4>
                      {subCategory && subCategory.third_box}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="card-gradient sec-padd">
            <div className="container">
              <h2 className="c-heading f-white n-border">
                {subCategory && subCategory.third_title}
              </h2>
              <div className="row g-md-5 g-4 pt-5">
                {subCategory.third_list && subCategory.third_list.map((data, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="icon-box">
                      <img
                        src={Image_Url + data.images}
                        alt="logo"
                      />
                      <h3>{data.title}</h3>
                      <p>
                        {data.descriptions}
                      </p>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </section>
          <section className="sec-padd lgray2 p-gray">
            <Container>
              <Row className="g-md-5 g-4 align-items-center">
                <Col md={6}>
                  <h2 className="b-heading n-border pb-4">
                    {subCategory && subCategory.fourth_title}
                  </h2>
                  <p>{subCategory && subCategory.fourth_descriptions}</p>

                </Col>
                <Col md={6}>
                  <img
                    src={subCategory && (Image_Url + subCategory.last_image)}
                    alt="logo"
                  />
                </Col>

              </Row>
            </Container>

          </section>
        </> :
        <><Comingsoon /></>
      }
    </>
  );
}
