import { combineReducers } from "redux";
import authenticationReducer from "./authenticationReducer"; 
import contactReducer from "./contactReducer"; 

const rootReducer = combineReducers({
  auth: authenticationReducer,
  contact: contactReducer,
   
});

export default rootReducer;
