import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  BsArrowRightCircleFill,
  BsFillPlayCircleFill,
  BsArrowRight,
} from "react-icons/bs";
import BlogCard from "../Blogs/BlogCard";
import { endPoints, Image_Url } from "../../Contant/Environment";
import { getAllItemsData, getAllItems } from "../../Utility/Api";
import { InfinitySpin } from 'react-loader-spinner'



function Home() {
  const [brandLogo, setBrandLogo] = useState([]);
  const [banner, setBanner] = useState([]);
  const [weOffer, setWeOffer] = useState([]);
  const [ourStory, setOurStory] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  const getHomePageData = () => {
    let url = `${endPoints.api.GET_HOMEPAGE}`;

    getAllItems(url)
      .then((response) => {
        const { status, data, message } = response;
        if (message === "success") {
          setBrandLogo(data.brand)
          setBanner(data.banner)
          setWeOffer(data.we_offer)
          setOurStory(data.our_story)
          setTestimonial(data.testimonial)
          setBlogs(data.blogs)
          setIsLoading(false);

        } else {
          // Handle error or show a message
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHomePageData();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  
  // console.log(brandLogo)
  return (
    <>
    {isLoading && <div className="page_loader">
      <InfinitySpin
        visible={true}
        width="200"
        color="#4fa94d"
        ariaLabel="infinity-spin-loading"
      />
      </div>}
      <Container fluid>
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper home-slider"
        >
          {banner && banner.map((data, index) => (

            <SwiperSlide>
              <img
                src={Image_Url + data.image}
                alt="slider"
              />
              <div className="slider-content">
                <Container>

                  <h2>
                    {data.title}
                  </h2>
                  <p>
                    {data.descriptions}
                  </p>

                  <div className="slider-button">
                    <Link to={"/contact"}>
                      <BsArrowRightCircleFill /> Get Service Today
                    </Link>
                    <Link to={data.video_link} target="_blank">
                      <BsFillPlayCircleFill /> Watch Video
                    </Link>
                  </div>
                </Container>
              </div>
            </SwiperSlide>
          )
          )}
        </Swiper>
      </Container>

      <section className="partner-slider sec-padd-b mt-4">
        <Container>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            grabCursor={true}
            speed={3000}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              767: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 7,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper logo-sec"
          >
            {brandLogo && brandLogo.map((data, index) => (
              <SwiperSlide>
                <img
                  src={Image_Url + data.image}
                  alt="slider"
                />
              </SwiperSlide>
            )
            )}


          </Swiper>
        </Container>
      </section>


      <section className="sec-padd">
        <Container>
          <Row>
            <Col md="8">


              <h2 className="c-heading m-heading">Our Service</h2>
              <p className="text-gray">
                Grow your business without compromising on Quality, Productivity &
                Control by moving to the cloud. Whatever  type of cloud
                services you use, Tech4Logic can help your organization have better
                cloud experience.
              </p>
            </Col>
          </Row>
          <div className="card-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              grabCursor={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              breakpoints={{
                "640": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "767": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "1024": {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                "1200": {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="card-slider"
            >
              <SwiperSlide>
                <div className="custom-card">
                  <div className="img-effect">
                    <img
                      src={require("../../Assets/Images/aws.webp")}
                      alt="slider"
                    />
                  </div>
                  <div className="card-content">
                    <h4>AMAZON Web Services (AWS) CLoud Computing Services</h4>
                    <p>
                      Experience AWS - Amazon Web Services- Elevate your digital
                      journey with on-demand cloud computing, autoscaling, and a
                      wide range of services for seamless growth.
                    </p>
                    <Link to="">
                      Learn More <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="custom-card">
                  <div className="img-effect">
                    <img
                      src={require("../../Assets/Images/aure.webp")}
                      alt="slider"
                    />
                  </div>
                  <div className="card-content">
                    <h4>
                      Microsoft Azure-Microsoft's cloud computing platform
                    </h4>
                    <p>
                      Elevate your organization with Azure, leveraging its
                      global data centers for secure, reliable data management
                      and seamless app development.
                    </p>
                    <Link to="">
                      Learn More <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="custom-card">
                  <div className="img-effect">
                    <img
                      src={require("../../Assets/Images/google.webp")}
                      alt="slider"
                    />
                  </div>
                  <div className="card-content">
                    <h4>Collaborate Seamlessly with Google Workspace</h4>
                    <p>
                      Google Workspace is Google's suite of cloud-based tools
                      for modern businesses. It includes Gmail, Calendar, Drive,
                      and more, all seamlessly integrated to enhance
                      collaboration.
                    </p>
                    <Link to="">
                      Learn More <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="custom-card">
                  <div className="img-effect">
                    <img
                      src={require("../../Assets/Images/aws.webp")}
                      alt="slider"
                    />
                  </div>
                  <div className="card-content">
                    <h4>AMAZON Web Services (AWS) CLoud Computing Services</h4>
                    <p>
                      Experience AWS - Amazon Web Services- Elevate your digital
                      journey with on-demand cloud computing, autoscaling, and a
                      wide range of services for seamless growth.
                    </p>
                    <Link to="">
                      Learn More <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </section>

      <div className="sec-padd">
        <Container fluid>
          <section className="offer-section sec-padd">
            <Container>
              <section className="trust-section sec-padd">
                <p className="para-m f-white">Our Track Record</p>
                <Row className="justify-content-between g-4">
                  <Col md="5">
                    <h2 className="c-heading f-white">
                      Trusted By Thousands Of Customers Worldwide
                    </h2>
                  </Col>
                  <Col md="5">
                    <p className="f-white">
                      For TECH4LOGIC, a stellar track record isn't just a goal;
                      it's a reality. Join us to be a part of our legacy of tech
                      achievements and client satisfaction.
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-between g-4 sec-padd-t">
                  <Col md="3" xs="6">
                    <div className="trust-box">
                      <h4>2431+</h4>
                      <p className="para-m  f-white">
                        Worldwide Active Customers
                      </p>
                    </div>
                  </Col>
                  <Col md="3" xs="6">
                    <div className="trust-box">
                      <h4>5+</h4>
                      <p className="para-m  f-white">
                        Worldwide Active Customers
                      </p>
                    </div>
                  </Col>
                  <Col md="3" xs="6">
                    <div className="trust-box">
                      <h4 className="f-white">24X7 Support, Experience</h4>
                    </div>
                  </Col>
                  <Col md="3" xs="6">
                    <div className="trust-box">
                      <h4 className="f-white">Custom Solutions Developement</h4>
                    </div>
                  </Col>
                </Row>
              </section>
              <div className="border-img sec-padd-b">
                <img
                  src={require("../../Assets/Images/border.png")}
                  alt="logo"
                />
              </div>

              <section className="sec-padd">
                <p className="blue-f">What We Offer</p>
                <h2 className="c-heading">{weOffer && weOffer.title}</h2>
                <p>
                  {weOffer && weOffer.descriptions ? parse(weOffer.descriptions) : ' '}
                </p>
                <div className="w-card-section sec-padd">
                  <Row className="g-4">
                    {weOffer.list && weOffer.list.map((data, index) => (
                      <Col md={2} xs={6}>
                        <div className="w-card">
                          <img
                            src={Image_Url + data.images}
                            alt="logo"
                          />
                          <p>
                            {data.title}
                          </p>
                        </div>
                      </Col>
                    )
                    )}
                  </Row>
                </div>
              </section>

              <section className="sec-padd home-story-section">
                <Row className="align-items-center g-5">
                  <Col md={6}>
                    <p className="blue-f">Our Story</p>
                    <h2 className="c-heading mb-5">
                      {ourStory && ourStory.title}{" "}
                    </h2>
                    <p className="mb-5">
                      {ourStory && ourStory.descriptions ? parse(ourStory.descriptions) : ' '}
                    </p>
                    <div className="pt-2">
                      <Link className="btn-style1" to="/about">
                        Know More
                      </Link>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="img-collpase">
                      <div className="big-img">
                        <img
                          src={ourStory && Image_Url + ourStory.image}
                          alt="logo"
                        />
                      </div>
                      <div className="small-img">
                        <img
                          src={ourStory && Image_Url + ourStory.image_second}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </section>
            </Container>
          </section>
        </Container>
      </div>

      <Container>
        <section className="sec-padd service-div">
          <Row className="align-items-center">
            <Col md={8}>
              <div className="service-content">
                <img src={require("../../Assets/Images/mail.gif")} alt="mail" />
                <div className="service-text">
                  <h2 className="c-heading f-white n-border">
                    Ready to Work With Us ? <br /> Give Us A Call today.
                  </h2>
                  <p className="f-white">
                    Choose Tech4Logic as your technology partner, and experience
                    the difference that people-powered solutions can make.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="service-button">
                <Link className="btn-style2" to={"/"}>
                  <BsArrowRightCircleFill /> Get Service Today
                </Link>
              </div>
            </Col>
          </Row>
        </section>
        <div className="border-img">
          <img src={require("../../Assets/Images/border.png")} alt="logo" />
        </div>
      </Container>

      <section className="sec-padd client-section">
        <Container>
          <p className="para-m  blue-f sec-padd-t">Testimonial</p>
          <h2 className="c-heading  mb-5">What Our Client Say About Us</h2>
          <Row className="align-items-center g-5">
            {testimonial && testimonial.map((data, index) => (

              <Col md={4}>
                <div className="client-card">
                  <p>
                    {parse(data.description)}
                  </p>
                  <div className="client-info">
                    <div className="client-img">
                      <img
                        src={Image_Url + data.image}
                        alt="logo"
                      />
                    </div>
                    <div className="client-name">
                      <p>{data.name}</p>
                      <p>{data.desgination}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="home-blog">
        <BlogCard blogs={blogs}   />
      </section>
    </>
  );
}

export default Home;
