export const endPoints = { 

     baseUrl: 'https://cruxcreativedemo2.com/tech4logic/',  
     apiBaseUrl: 'https://cruxcreativedemo2.com/tech4logic/api',  
  
    api: {
      GET_HOMEPAGE:"home-pages",
      GET_ABOUTPAGE:"about-pages",
      GET_BLOGS:"blogs",
      GET_CATEGORY:"category",
      GET_SUBCATEGORY:"sub-category/",
      GET_SERVICES:"services/",
      QUERYSUBMIT:"enquery-form",
      
    },
    mode: "Development",
  };
 
  export const Image_Url = "https://cruxcreativedemo2.com/tech4logic/public/uploads/" 
 
  
  export default {};
  