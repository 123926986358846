import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CustomCard2({ list }) {
  // console.log(list)
  return (
    <>
      <div className="custom-card1">
        <Row className="g-4">
          {list && list.map((data, index) => (
            <Col md={3} sm={6}>
              <div className="w-card">
                <img
                  src={require("../../Assets/Images/card-icon1.svg").default}
                  alt="logo"
                />
                <h4>{data.title}</h4>
                <p>{data.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}

export default CustomCard2