import React from 'react' 
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CsrCard() {
  return (
    <>
         <div className="custom-card1 csr-card bottom-r-dots circle-top-left">
                <Row className="g-4">
                  <Col md={3} sm={6}>
                    <div className="w-card">
                      <img
                        src={require("../../Assets/Images/csr-icon1.svg").default}
                        alt="logo"
                      />
                    <h4>Eco-Friendliness</h4>
                    <p>Implementing green tech for a cleaner, sustainable environment, reducing our ecological footprint significantly.</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/csr-icon2.svg").default}
                        alt="logo"
                      />
                    <h4>Social Responsibility</h4>
                    <p>Empowering communities via education, healthcare, and initiatives enhancing their quality of life for inclusivity</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/csr-icon3.svg").default}
                        alt="logo"
                      />
                    <h4>Ethical Business Practices</h4>
                    <p>Adhering to transparent, fair, and ethical business conduct, ensuring trust, credibility, and responsible partnerships</p>
                    </div>
                  </Col>
                  <Col md={3} sm={6}>
                  <div className="w-card">
                      <img
                        src={require("../../Assets/Images/csr-icon4.svg").default}
                        alt="logo"
                      />
                    <h4>Community Engagement</h4>
                    <p>Actively engaging local communities, understanding their needs, and collaborating on initiatives for a positive impact.</p>
                    </div>
                  </Col> 
                </Row>
              </div>
    </>
  )
}

export default CsrCard