import React, { useState, Suspense, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Careers from './Components/Careers/Careers';
import Blogs from './Components/Blogs/Blogs';
import Contact from './Components/Contact/Contact';
import BlogDetail from './Components/BlogDetail/BlogDetail';
import Faqs from './Components/Faqs/Faqs';
import Csr from './Components/Csr/Csr';
import Service from './Components/Service/Service';
import VideoPlayer from './Components/Service/test/VideoPlayer';
import Comingsoon from './Components/CommingSoon';
import AwsAnalytics from './Components/Services/Aws/AwsAnalytics';
import AWSApplicationIntegration from './Components/Services/AWSApplicationIntegration';
import AWSBusinessApplications from './Components/Services/AWSBusinessApplications';
import AWSCompute from './Components/Services/AWSCompute';
import AWSDatabase from './Components/Services/AWSDatabase';
import AWSMigrationTransfer from './Components/Services/AWSMigrationTransfer';
import AWSCloudDevOps from './Components/Services/AWSCloudDevOps';
import AWSSecurityIdentityCompliance from './Components/Services/Aws/AWSSecurityIdentityCompliance';
import AWSServiceHome from './Components/Services/Aws/AWSServiceHome';
import Loading from "./Components/Loading";
import { clearCache } from './clearCache';

const RouteList = (props) => {
    clearCache();

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/careers' element={<Careers />} />
                <Route path='/blogs' element={<Blogs />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/blog/:slug' element={<BlogDetail />} />
                <Route path='/faqs' element={<Faqs />} />
                <Route path='/csr' element={<Csr />} />
                <Route path='/service' element={<Service />} />
                <Route path='/VideoPlayer' element={<VideoPlayer />} />
                <Route path='/comingsoon' element={<Comingsoon />} />
                <Route path='/:subCat' element={<AWSServiceHome />} />
                {/* <Route path='/clearCache ' element={<clearCache />} /> */}
                <Route path='/:subCat/:service' element={<AwsAnalytics />} />
            </Routes>
        </Suspense>
    );
};

export default RouteList;