import React, { useRef, useEffect, useState } from 'react';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [resumeTime, setResumeTime] = useState(0);

  useEffect(() => {
    const storedResumeTime = localStorage.getItem('videoResumeTime');

    if (storedResumeTime) {
      setResumeTime(parseFloat(storedResumeTime));
    }

    // Cleanup function
    return () => {
      if (videoRef.current) {
        localStorage.setItem('videoResumeTime', String(videoRef.current.currentTime));
      }
    };
  }, []);

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div>
      <video ref={videoRef} controls onPlay={handleVideoPlay}>
        <source src="your-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
