import { React, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TiLocation } from "react-icons/ti";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsArrowRight } from "react-icons/bs"
import { FaPhone, FaEnvelope } from "react-icons/fa6";
import { useSelector } from "react-redux";


function Footer() {
  const [validated, setValidated] = useState(false);
  const { contact } = useSelector((state) => state);
  const contact_data = contact.contact_data;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <footer>
      <div className="sec-padd">
        <Container>
          <Row>
            <Col lg={3} md={6}>
              <ul>
                {contact_data.office_address && contact_data.office_address.map((data, index) => (
                  <li>
                    <div className="icon-div">
                      <TiLocation />
                    </div>
                    <div className="location-info">
                      <h4>{data.state}</h4>
                      <p>
                        {data.address}
                      </p>
                    </div>
                  </li>
                ))}

              </ul>
            </Col>
            <Col md={3} xs={6}>
              <ul>
                <li>
                  <Link to={"/about"} onClick={scrollToTop}>About </Link>
                </li>
                <li>
                  <Link to={"/about"} onClick={scrollToTop}>Services </Link>
                </li>
                <ul className="footer-submenu ps-0">
                  <li>
                    {" "}
                    <Link to={"/"} onClick={scrollToTop}>Services </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/"} onClick={scrollToTop}>Azure backup Services </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/"} onClick={scrollToTop}>Design Services </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/"} onClick={scrollToTop}>Cloud Computing </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to={"/"} onClick={scrollToTop}>Digital Marketing </Link>
                  </li>
                </ul>
                <li>
                  <Link to={"/careers"} onClick={scrollToTop}>Career </Link>
                </li>
                <li>
                  <Link to={"/blogs"} onClick={scrollToTop}>Blogs </Link>
                </li>
              </ul>
            </Col>
            <Col md={3} xs={6}>
              <ul>
                <li>
                  <Link to={"/contact"} onClick={scrollToTop}>Contact </Link>
                </li>
                <li>
                  <Link to={"/csr"} onClick={scrollToTop}>CSR </Link>
                </li>
                <li>
                  <Link to={"/faqs"}>FAQs </Link>
                </li>
                <li>
                  <Link to={"/"}>Privacy Policy </Link>
                </li>
                <li>
                  <Link to={"/"}>Terms & Conditions</Link>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <h4 className="form-heading">Sign up for our newsletter</h4>
              <p>
                Subscribe to get the latest design news, articles, resources and
                inspiration.
              </p>
              <Form className="footer-form" noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Group
                  controlId="validationCustomUsername"
                >
                  <InputGroup hasValidation>

                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      aria-describedby="inputGroupPrepend"
                      required
                    />

                    <Button type="submit"><BsArrowRight /></Button>

                  </InputGroup>

                </Form.Group>

              </Form>

              <div className="flex-div">
                <img src={require("../../Assets/Images/ISO.webp")} alt="logo" />
                <img src={require("../../Assets/Images/gem.webp")} alt="logo" />

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copy-footer">
        <Container>
          <p>© Tech4Logic Pvt Ltd - 2014 to 2023 | All Rights Reserved</p>
        </Container>
      </div>

    </footer>
  );
}

export default Footer;
