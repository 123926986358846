import * as Yup from "yup";

export const contactSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    email: Yup.string().email().required("Please enter your email"),
    mobile: Yup.string().length(10, "Please enter a valid mobile number").required("Please enter your mobile"),
    comment: Yup.string().required("Please enter your comment")
    
  });

 