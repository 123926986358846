import { useState } from "react";
import Modal from "react-bootstrap/Modal";

function CareerModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <a className="s-link" onClick={handleShow}>
        View Details
      </a>

      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
        className="model-box"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div >
            <h4>Job Description</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <h4>Task</h4>

            <p>Work on Frontend/UI Development </p>
            <p>Optimize website for performance </p>
            <p>Keep up with the latest and emerging front-end technologies</p>

            <h4>Requirements</h4>
            <p>
              At least 2 years development experience with either Vue.js or
              React
            </p>
            <p>Strong understanding of HTML5, CSS3, DOM, REST and JSON</p>
            <p>Keen eye for design</p>
            <p>Experience with important third-party libraries</p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default CareerModal;
