import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { Image_Url } from "../../Contant/Environment";
import { formatDateByMonth } from "../../Utility/AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactPaginate from 'react-paginate';

function BlogItem({ blogs }) {
  const [pageNumber, setPageNumber] = useState(0);
  let navigate = useNavigate();
  const navigateToBlog = (item) => {
    navigate((`/blog/${item.slug}`), { state: { item } });
  };
  const blogsPerPage = 2;
  const pagesVisited = pageNumber * blogsPerPage;
  const displayedBlogs = blogs.slice(pagesVisited, pagesVisited + blogsPerPage);

  return (
    <>

      {displayedBlogs && displayedBlogs.map((data, index) => (
        <div className="blog-post">
          <div className="main-div-post">
            <div className="img-post">
              <img
                src={Image_Url + data.image}
                alt="logo"
              />
            </div>
            <div className="post-main-div">
              <div className="blog-info">
                <span>{data.type}</span> <p>{formatDateByMonth(data.created_at)}</p>
              </div>
              <div className="post-info-blog">
                <div className="post-content">
                  <h4>
                    {data.title}
                  </h4>
                  <p>
                    {parse(data.descriptions)}
                  </p>
                </div>
                <div className="post-info">
                  <div className="post-profile">
                    <img
                      src={require("../../Assets/Images/blog1.png")}
                      alt="logo"
                    />
                    <div className="post-profile-name">
                      <h5>Jessica William</h5>
                      <p>Software Dev.</p>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <Button className="btn-style7" onClick={() => navigateToBlog(data)}>Read Now</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={Math.ceil(blogs.length / blogsPerPage)}
          onPageChange={(e) => setPageNumber(e.selected)}
          containerClassName={"paginationBtns"}
          previousLinkClassName={"previousBtn"}
          nextLinkClassName={"nextBtn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      
    </>
  )
}

export default BlogItem