import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsArrowRightCircleFill, BsArrowRight } from "react-icons/bs";

function SignUpNews() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
  return (
    <>
         <div className="service-div  bg-gradient2 left-circle">
            <div className="sec-padd">
              <Row className="align-items-center">
                <Col md={6}>
                  <h2 className="c-heading n-border f-white">
                    Signup to our newsletter
                  </h2>
                  <p className="p-small f-white">
                    Get the latest LinkMyDeals news straight to your inbox
                  </p>
                </Col>
                <Col md={6}>
                  <Form
                    className="footer-form career-form"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          placeholder="Enter Here"
                          aria-describedby="inputGroupPrepend"
                          required
                        />

                        <Button type="submit">
                          Subscribe <BsArrowRight />
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
    </>
  )
}

export default SignUpNews