import React, { useRef, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { ImLinkedin } from "react-icons/im";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Accordion from "react-bootstrap/Accordion";
import { endPoints, Image_Url } from "../../Contant/Environment";
import { getAllItemsData, getAllItems } from "../../Utility/Api";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../redux/Actions/Actions";

function Header() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const [category, setCategory] = useState([]);
  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  const el = document.querySelector('.navbar-collapse.collapse');


  const handleClick1 = (e) => {
    // console.log(e.target.className);
    let a = 'mega-link';
    let string = e.target.className;

    //   if (string.includes(a)) {
    //     el.classList.remove("show");
    // }
  };

  const getCategory = () => {
    let url = `${endPoints.api.GET_CATEGORY}`;

    getAllItems(url)
      .then((response) => {
        const { status, data, message } = response;
        if (message === "success") {
          setCategory(data.category_data)
          setContact(data.contact_data)
          dispatch({
            type: Actions.SET_CONATACT_DATA,
            data: { contact_data: data.contact_data },
          });
        } else {
          // Handle error or show a message
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategory();
  }, []);
  // console.log(contact)

  return (
    <>
      <header sticky="top">
        <Navbar collapseOnSelect expand="lg" onClick={handleClick1}>
          <Container>
            <Link to="/" onClick={scrollToTop}>
              <img
                src={require("../../Assets/Images/Tech4logic.svg").default}
                className="logo"
                alt="logo"
              />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <Link className="mega-link" to="/about" onClick={scrollToTop}>
                  About
                </Link>

                <NavDropdown
                  title="Services"
                  className="d-none d-md-block"
                  id="collapsible-nav-dropdown"
                >
                  {category && category.map((data, index) => (

                    <NavDropdown.Item href="#">
                      <span>
                        <img
                          src={Image_Url + data.image}
                          alt="logo"
                        />{" "}
                        {data.name}
                      </span>{" "}
                      <span>
                        {" "}
                        <MdOutlineArrowForwardIos />
                      </span>
                      {(data.sub_category).length > 0 &&
                        <div className="menu-hover-content">
                          {data.sub_category.map((data1, index) => (
                            <div className="menu-hover-item">
                              <Link className="link_a" onClick={scrollToTop} to={`/${data1.slug}-services`}>
                                <img
                                  src={Image_Url + data1.image}
                                  alt="logo"
                                />
                                <div className="menu-hover-text">
                                  <h5>{data1.title}</h5>
                                  <p>
                                    {data1.descriptions}{" "}
                                    <span>
                                      {" "}
                                      <MdOutlineArrowForwardIos />
                                    </span>
                                  </p>
                                </div>
                              </Link>
                              <div className="menu-hover-content inner-menu">
                                <div className="menu-hover-item">
                                  <ul>
                                    {data1.services.map((data2, index) => (

                                      <li>
                                        <Link onClick={scrollToTop} to={`${data1.slug}/${data2.slug}`}>
                                          <MdOutlineArrowForwardIos /> {data2.main_title}
                                        </Link>
                                      </li>
                                    ))}


                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>

                <div className="d-block d-md-none mobile-subenu">

                  <Accordion>
                    <Accordion.Item eventKey="0001">
                      <Accordion.Header>Services  <MdOutlineArrowForwardIos /></Accordion.Header>
                      <Accordion.Body>
                        <Accordion>
                          {category && category.map((data, index) => (
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {" "}
                                <span>
                                  <img
                                    src={Image_Url + data.image}
                                    alt="logo"
                                  />{" "}
                                  {data.name}
                                </span>{" "}
                                <span>
                                  {" "}
                                  <MdOutlineArrowForwardIos />
                                </span>
                              </Accordion.Header>
                              {(data.sub_category).length > 0 &&
                                <Accordion.Body>
                                  <Accordion>
                                    {data.sub_category.map((data1, index1) => (
                                      <Accordion.Item eventKey={index + index1}>
                                        <Accordion.Header><img
                                          src={Image_Url + data1.image}
                                          alt="logo"
                                        /> <span>{data1.title} <MdOutlineArrowForwardIos /></span></Accordion.Header>
                                        {data1.services &&
                                          <Accordion.Body>
                                            <div className="menu-hover-item">
                                              <ul>
                                                {data1.services.map((data2, index2) => (
                                                  <li><Link to={`service/${data2.slug}`}><MdOutlineArrowForwardIos /> {data2.main_title}</Link></li>
                                                ))}
                                              </ul>
                                            </div>
                                          </Accordion.Body>
                                        }
                                      </Accordion.Item>
                                    ))}
                                  </Accordion>
                                </Accordion.Body>
                              }
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>


                </div>

                <Link to="/careers" onClick={scrollToTop}>
                  Careers
                </Link>
                <Link to="blogs" onClick={scrollToTop}>
                  Blogs
                </Link>
                <Link to="/contact" onClick={scrollToTop}>
                  Contact
                </Link>
              </Nav>
              <Nav className="right-nav">
                <Link className="b-btn" to="contact">
                  Get In Touch
                </Link>
                <div className="social-item">
                  <Nav.Link
                    href={contact && contact.facebook_link}
                    target="_blank"
                  >
                    <FaSquareFacebook />
                  </Nav.Link>
                  <Nav.Link href={contact && contact.twitter} target="_blank">
                    <FaSquareXTwitter />
                  </Nav.Link>
                  <Nav.Link href={contact && contact.linkedin} target="_blank">
                    <ImLinkedin />
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Header;
